import { AxiosResponse } from 'axios';
import { all, call, put, fork, takeLatest } from 'redux-saga/effects';
import { GetSpecialtyDTO, Specialty } from './types';
import SpecialtyApi from 'src/Services/Api/SpecialtyApi';
import {
  getSpecialtiesByDocumentIdError,
  getSpecialtiesByDocumentIdSuccess,
  getSpecialtiesError,
  getSpecialtiesSuccess,
  getSpecialtyError,
  getSpecialtySuccess,
} from './actions';
import { SpecialtyActionTypes } from './types';

function* handleGetSpecialties() {
  try {
    const result: AxiosResponse<Specialty[]> = yield call(
      SpecialtyApi.getAllSpecialties,
    );

    if (result.status === 200 && result.data !== null) {
      yield put(getSpecialtiesSuccess(result.data));
    } else {
      yield put(getSpecialtiesError(result.statusText));
    }
  } catch (error) {
    if (error instanceof Error && error.stack) {
      yield put(getSpecialtiesError(error.stack));
    } else {
      yield put(getSpecialtiesError('Um erro inesperado aconteceu'));
    }
  }
}

function* handleGetSpecialty(action) {
  try {
    const id = action.payload;
    const result: AxiosResponse<Specialty> = yield call(
      SpecialtyApi.getSpecialty,
      id,
    );

    if (result.status === 200 && result.data !== null) {
      yield put(getSpecialtySuccess(result.data));
    } else {
      yield put(getSpecialtyError(result.statusText));
    }
  } catch (error) {
    if (error instanceof Error && error.stack) {
      yield put(getSpecialtyError(error.stack));
    } else {
      yield put(getSpecialtyError('Um erro inesperado aconteceu'));
    }
  }
}

function* handleGetSpecialtiesByDocumentId(action) {
  try {
    const documentId = action.payload;
    const result: AxiosResponse<GetSpecialtyDTO[]> = yield call(
      SpecialtyApi.getSpecialtiesByDocumentId,
      documentId,
    );

    if (result.status === 200 && result.data !== null) {
      yield put(getSpecialtiesByDocumentIdSuccess(result.data));
    } else {
      yield put(getSpecialtiesByDocumentIdError(result.statusText));
    }
  } catch (error) {
    if (error instanceof Error && error.stack) {
      yield put(getSpecialtiesByDocumentIdError(error.stack));
    } else {
      yield put(getSpecialtiesByDocumentIdError('Um erro inesperado aconteceu'));
    }
  }
}

function* watchGetSpecialties() {
  yield takeLatest(
    SpecialtyActionTypes.GET_SPECIALTIES,
    handleGetSpecialties,
  );
}

function* watchGetSpecialty() {
  yield takeLatest(
    SpecialtyActionTypes.GET_SPECIALTY,
    handleGetSpecialty,
  );
}

function* watchGetSpecialtiesByDocumentId() {
  yield takeLatest(
    SpecialtyActionTypes.GET_SPECIALTIES_BY_DOCUMENT_ID,
    handleGetSpecialtiesByDocumentId,
  );
}

function* specialtySaga() {
  yield all([
    fork(watchGetSpecialties),
    fork(watchGetSpecialty),
    fork(watchGetSpecialtiesByDocumentId),
  ]);
}

export default specialtySaga; 