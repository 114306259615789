import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';

export interface ProfessionalCouncilDocumentDTO {
  id?: number;
  documentCode: string;
  active?: boolean;
}

export enum ProfessionalCouncilDocumentActionTypes {
  GET_PROFESSIONAL_COUNCIL_DOCUMENTS = '@@professionalCouncilDocument/GET_PROFESSIONAL_COUNCIL_DOCUMENTS',
  GET_PROFESSIONAL_COUNCIL_DOCUMENTS_SUCCESS = '@@professionalCouncilDocument/GET_PROFESSIONAL_COUNCIL_DOCUMENTS_SUCCESS',
  GET_PROFESSIONAL_COUNCIL_DOCUMENTS_ERROR = '@@professionalCouncilDocument/GET_PROFESSIONAL_COUNCIL_DOCUMENTS_ERROR',

  GET_PROFESSIONAL_COUNCIL_DOCUMENT = '@@professionalCouncilDocument/GET_PROFESSIONAL_COUNCIL_DOCUMENT',
  GET_PROFESSIONAL_COUNCIL_DOCUMENT_SUCCESS = '@@professionalCouncilDocument/GET_PROFESSIONAL_COUNCIL_DOCUMENT_SUCCESS',
  GET_PROFESSIONAL_COUNCIL_DOCUMENT_ERROR = '@@professionalCouncilDocument/GET_PROFESSIONAL_COUNCIL_DOCUMENT_ERROR',

  RESET = '@@professionalCouncilDocument/RESET',
}

export interface ProfessionalCouncilDocumentState {
  readonly professionalCouncilDocuments?: ProfessionalCouncilDocumentDTO[];
  readonly professionalCouncilDocument?: ProfessionalCouncilDocumentDTO | null;
  readonly getDocumentsStatus?: HttpRequestStatus;
  readonly getDocumentStatus?: HttpRequestStatus;
} 