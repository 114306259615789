import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';

export interface Specialty {
  id?: number;
  name: string;
  active?: boolean;
}

export interface GetSpecialtyDTO {
  id?: number;
  specialtyName: string;
  documentId?: number;
}

export enum SpecialtyActionTypes {
  GET_SPECIALTIES = '@@specialty/GET_SPECIALTIES',
  GET_SPECIALTIES_SUCCESS = '@@specialty/GET_SPECIALTIES_SUCCESS',
  GET_SPECIALTIES_ERROR = '@@specialty/GET_SPECIALTIES_ERROR',

  GET_SPECIALTY = '@@specialty/GET_SPECIALTY',
  GET_SPECIALTY_SUCCESS = '@@specialty/GET_SPECIALTY_SUCCESS',
  GET_SPECIALTY_ERROR = '@@specialty/GET_SPECIALTY_ERROR',

  GET_SPECIALTIES_BY_DOCUMENT_ID = '@@specialty/GET_SPECIALTIES_BY_DOCUMENT_ID',
  GET_SPECIALTIES_BY_DOCUMENT_ID_SUCCESS = '@@specialty/GET_SPECIALTIES_BY_DOCUMENT_ID_SUCCESS',
  GET_SPECIALTIES_BY_DOCUMENT_ID_ERROR = '@@specialty/GET_SPECIALTIES_BY_DOCUMENT_ID_ERROR',

  CLEAR_SPECIALTIES_BY_DOCUMENT = '@@specialty/CLEAR_SPECIALTIES_BY_DOCUMENT',

  RESET = '@@specialty/RESET',
}

export interface SpecialtyState {
  readonly specialties?: Specialty[];
  readonly specialty?: Specialty | null;
  readonly specialtiesByDocument?: GetSpecialtyDTO[];
  readonly getSpecialtiesStatus?: HttpRequestStatus;
  readonly getSpecialtyStatus?: HttpRequestStatus;
  readonly getSpecialtiesByDocumentStatus?: HttpRequestStatus;
} 