import { AxiosResponse } from 'axios';
import { api } from '.';
import { 
  ProfessionalCouncilDocumentDTO 
} from 'src/Stores/professionalCouncilDocument/types';

const ProfessionalCouncilDocumentApi = () => {
  const getAllProfessionalCouncilDocuments = (): Promise<AxiosResponse<ProfessionalCouncilDocumentDTO[]>> => {
    return api.get<ProfessionalCouncilDocumentDTO[]>('/professional-council-documents');
  };

  const getProfessionalCouncilDocument = (id: number): Promise<AxiosResponse<ProfessionalCouncilDocumentDTO>> => {
    return api.get<ProfessionalCouncilDocumentDTO>(`/professional-council-documents/${id}`);
  };

  return {
    getAllProfessionalCouncilDocuments,
    getProfessionalCouncilDocument,
  };
};

export default ProfessionalCouncilDocumentApi(); 