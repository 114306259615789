import { Reducer } from 'redux';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { ProfessionalCouncilDocumentActionTypes, ProfessionalCouncilDocumentState } from './types';

export const initialState: ProfessionalCouncilDocumentState = {
  professionalCouncilDocuments: [],
  professionalCouncilDocument: null,
  getDocumentsStatus: HttpRequestStatus.NOOP,
  getDocumentStatus: HttpRequestStatus.NOOP,
};

const reducer: Reducer<ProfessionalCouncilDocumentState> = (state = initialState, action) => {
  switch (action.type) {
    case ProfessionalCouncilDocumentActionTypes.GET_PROFESSIONAL_COUNCIL_DOCUMENTS: {
      return {
        ...state,
        getDocumentsStatus: HttpRequestStatus.ONGOING,
      };
    }
    case ProfessionalCouncilDocumentActionTypes.GET_PROFESSIONAL_COUNCIL_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        professionalCouncilDocuments: action.payload,
        getDocumentsStatus: HttpRequestStatus.SUCCESS,
      };
    }
    case ProfessionalCouncilDocumentActionTypes.GET_PROFESSIONAL_COUNCIL_DOCUMENTS_ERROR: {
      return {
        ...state,
        getDocumentsStatus: HttpRequestStatus.ERROR,
      };
    }

    case ProfessionalCouncilDocumentActionTypes.GET_PROFESSIONAL_COUNCIL_DOCUMENT: {
      return {
        ...state,
        getDocumentStatus: HttpRequestStatus.ONGOING,
      };
    }
    case ProfessionalCouncilDocumentActionTypes.GET_PROFESSIONAL_COUNCIL_DOCUMENT_SUCCESS: {
      return {
        ...state,
        professionalCouncilDocument: action.payload,
        getDocumentStatus: HttpRequestStatus.SUCCESS,
      };
    }
    case ProfessionalCouncilDocumentActionTypes.GET_PROFESSIONAL_COUNCIL_DOCUMENT_ERROR: {
      return {
        ...state,
        getDocumentStatus: HttpRequestStatus.ERROR,
      };
    }
    
    case ProfessionalCouncilDocumentActionTypes.RESET: {
      return {
        ...initialState,
      };
    }
    
    default: {
      return state;
    }
  }
};

export { reducer as professionalCouncilDocumentReducer }; 