import { AxiosResponse } from 'axios';
import { api } from '.';
import { 
  GetSpecialtyDTO,
  Specialty,
} from 'src/Stores/specialty/types';

const SpecialtyApi = () => {
  const getAllSpecialties = (): Promise<AxiosResponse<Specialty[]>> => {
    return api.get<Specialty[]>('/specialty');
  };

  const getSpecialty = (id: number): Promise<AxiosResponse<Specialty>> => {
    return api.get<Specialty>(`/specialty/${id}`);
  };

  const getSpecialtiesByDocumentId = (documentId: number): Promise<AxiosResponse<GetSpecialtyDTO[]>> => {
    return api.get<GetSpecialtyDTO[]>(`/specialty/document/${documentId}`);
  };

  return {
    getAllSpecialties,
    getSpecialty,
    getSpecialtiesByDocumentId,
  };
};

export default SpecialtyApi(); 