import { action } from 'typesafe-actions';
import { 
  SpecialtyActionTypes, 
  Specialty, 
  GetSpecialtyDTO 
} from './types';

export const getSpecialties = () =>
  action(SpecialtyActionTypes.GET_SPECIALTIES);
export const getSpecialtiesSuccess = (specialties: Specialty[]) =>
  action(SpecialtyActionTypes.GET_SPECIALTIES_SUCCESS, specialties);
export const getSpecialtiesError = (message: string) =>
  action(SpecialtyActionTypes.GET_SPECIALTIES_ERROR, message);

export const getSpecialty = (id: number) =>
  action(SpecialtyActionTypes.GET_SPECIALTY, id);
export const getSpecialtySuccess = (specialty: Specialty) =>
  action(SpecialtyActionTypes.GET_SPECIALTY_SUCCESS, specialty);
export const getSpecialtyError = (message: string) =>
  action(SpecialtyActionTypes.GET_SPECIALTY_ERROR, message);

export const getSpecialtiesByDocumentId = (documentId: number) =>
  action(SpecialtyActionTypes.GET_SPECIALTIES_BY_DOCUMENT_ID, documentId);
export const getSpecialtiesByDocumentIdSuccess = (specialties: GetSpecialtyDTO[]) =>
  action(SpecialtyActionTypes.GET_SPECIALTIES_BY_DOCUMENT_ID_SUCCESS, specialties);
export const getSpecialtiesByDocumentIdError = (message: string) =>
  action(SpecialtyActionTypes.GET_SPECIALTIES_BY_DOCUMENT_ID_ERROR, message);

export const clearSpecialtiesByDocument = () =>
  action(SpecialtyActionTypes.CLEAR_SPECIALTIES_BY_DOCUMENT);

export const reset = () =>
  action(SpecialtyActionTypes.RESET); 