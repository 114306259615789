import { action } from 'typesafe-actions';
import { ProfessionalCouncilDocumentActionTypes, ProfessionalCouncilDocumentDTO } from './types';

export const getProfessionalCouncilDocuments = () =>
  action(ProfessionalCouncilDocumentActionTypes.GET_PROFESSIONAL_COUNCIL_DOCUMENTS);
export const getProfessionalCouncilDocumentsSuccess = (documents: ProfessionalCouncilDocumentDTO[]) =>
  action(ProfessionalCouncilDocumentActionTypes.GET_PROFESSIONAL_COUNCIL_DOCUMENTS_SUCCESS, documents);
export const getProfessionalCouncilDocumentsError = (message: string) =>
  action(ProfessionalCouncilDocumentActionTypes.GET_PROFESSIONAL_COUNCIL_DOCUMENTS_ERROR, message);

export const getProfessionalCouncilDocument = (id: number) =>
  action(ProfessionalCouncilDocumentActionTypes.GET_PROFESSIONAL_COUNCIL_DOCUMENT, id);
export const getProfessionalCouncilDocumentSuccess = (document: ProfessionalCouncilDocumentDTO) =>
  action(ProfessionalCouncilDocumentActionTypes.GET_PROFESSIONAL_COUNCIL_DOCUMENT_SUCCESS, document);
export const getProfessionalCouncilDocumentError = (message: string) =>
  action(ProfessionalCouncilDocumentActionTypes.GET_PROFESSIONAL_COUNCIL_DOCUMENT_ERROR, message);

export const reset = () =>
  action(ProfessionalCouncilDocumentActionTypes.RESET); 