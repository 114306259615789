import { AxiosResponse } from 'axios';
import { all, call, put, fork, takeLatest } from 'redux-saga/effects';
import { ProfessionalCouncilDocumentDTO } from './types';
import ProfessionalCouncilDocumentApi from 'src/Services/Api/ProfessionalCouncilDocumentApi';
import {
  getProfessionalCouncilDocumentError,
  getProfessionalCouncilDocumentSuccess,
  getProfessionalCouncilDocumentsError,
  getProfessionalCouncilDocumentsSuccess,
} from './actions';
import { ProfessionalCouncilDocumentActionTypes } from './types';

function* handleGetProfessionalCouncilDocuments() {
  try {
    const result: AxiosResponse<ProfessionalCouncilDocumentDTO[]> = yield call(
      ProfessionalCouncilDocumentApi.getAllProfessionalCouncilDocuments,
    );

    if (result.status === 200 && result.data !== null) {
      yield put(getProfessionalCouncilDocumentsSuccess(result.data));
    } else {
      yield put(getProfessionalCouncilDocumentsError(result.statusText));
    }
  } catch (error) {
    if (error instanceof Error && error.stack) {
      yield put(getProfessionalCouncilDocumentsError(error.stack));
    } else {
      yield put(getProfessionalCouncilDocumentsError('Um erro inesperado aconteceu'));
    }
  }
}

function* handleGetProfessionalCouncilDocument(action) {
  try {
    const id = action.payload;
    const result: AxiosResponse<ProfessionalCouncilDocumentDTO> = yield call(
      ProfessionalCouncilDocumentApi.getProfessionalCouncilDocument,
      id,
    );

    if (result.status === 200 && result.data !== null) {
      yield put(getProfessionalCouncilDocumentSuccess(result.data));
    } else {
      yield put(getProfessionalCouncilDocumentError(result.statusText));
    }
  } catch (error) {
    if (error instanceof Error && error.stack) {
      yield put(getProfessionalCouncilDocumentError(error.stack));
    } else {
      yield put(getProfessionalCouncilDocumentError('Um erro inesperado aconteceu'));
    }
  }
}

function* watchGetProfessionalCouncilDocuments() {
  yield takeLatest(
    ProfessionalCouncilDocumentActionTypes.GET_PROFESSIONAL_COUNCIL_DOCUMENTS,
    handleGetProfessionalCouncilDocuments,
  );
}

function* watchGetProfessionalCouncilDocument() {
  yield takeLatest(
    ProfessionalCouncilDocumentActionTypes.GET_PROFESSIONAL_COUNCIL_DOCUMENT,
    handleGetProfessionalCouncilDocument,
  );
}

function* professionalCouncilDocumentSaga() {
  yield all([
    fork(watchGetProfessionalCouncilDocuments),
    fork(watchGetProfessionalCouncilDocument),
  ]);
}

export default professionalCouncilDocumentSaga; 