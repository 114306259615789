import { Reducer } from 'redux';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { SpecialtyActionTypes, SpecialtyState } from './types';

export const initialState: SpecialtyState = {
  specialties: [],
  specialty: null,
  specialtiesByDocument: [],
  getSpecialtiesStatus: HttpRequestStatus.NOOP,
  getSpecialtyStatus: HttpRequestStatus.NOOP,
  getSpecialtiesByDocumentStatus: HttpRequestStatus.NOOP,
};

const reducer: Reducer<SpecialtyState> = (state = initialState, action) => {
  switch (action.type) {
    case SpecialtyActionTypes.GET_SPECIALTIES: {
      return {
        ...state,
        getSpecialtiesStatus: HttpRequestStatus.ONGOING,
      };
    }
    case SpecialtyActionTypes.GET_SPECIALTIES_SUCCESS: {
      return {
        ...state,
        specialties: action.payload,
        getSpecialtiesStatus: HttpRequestStatus.SUCCESS,
      };
    }
    case SpecialtyActionTypes.GET_SPECIALTIES_ERROR: {
      return {
        ...state,
        getSpecialtiesStatus: HttpRequestStatus.ERROR,
      };
    }

    case SpecialtyActionTypes.GET_SPECIALTY: {
      return {
        ...state,
        getSpecialtyStatus: HttpRequestStatus.ONGOING,
      };
    }
    case SpecialtyActionTypes.GET_SPECIALTY_SUCCESS: {
      return {
        ...state,
        specialty: action.payload,
        getSpecialtyStatus: HttpRequestStatus.SUCCESS,
      };
    }
    case SpecialtyActionTypes.GET_SPECIALTY_ERROR: {
      return {
        ...state,
        getSpecialtyStatus: HttpRequestStatus.ERROR,
      };
    }

    case SpecialtyActionTypes.GET_SPECIALTIES_BY_DOCUMENT_ID: {
      return {
        ...state,
        getSpecialtiesByDocumentStatus: HttpRequestStatus.ONGOING,
      };
    }
    case SpecialtyActionTypes.GET_SPECIALTIES_BY_DOCUMENT_ID_SUCCESS: {
      return {
        ...state,
        specialtiesByDocument: action.payload,
        getSpecialtiesByDocumentStatus: HttpRequestStatus.SUCCESS,
      };
    }
    case SpecialtyActionTypes.GET_SPECIALTIES_BY_DOCUMENT_ID_ERROR: {
      return {
        ...state,
        getSpecialtiesByDocumentStatus: HttpRequestStatus.ERROR,
      };
    }
    
    case SpecialtyActionTypes.CLEAR_SPECIALTIES_BY_DOCUMENT: {
      return {
        ...state,
        specialtiesByDocument: [],
        getSpecialtiesByDocumentStatus: HttpRequestStatus.NOOP,
      };
    }

    case SpecialtyActionTypes.RESET: {
      return {
        ...initialState,
      };
    }
    
    default: {
      return state;
    }
  }
};

export { reducer as specialtyReducer }; 